import React from "react";
import Layout from "../components/layout";
import BlogsDetail from "../components/blogsDetailPageComponents/blogsDetail";

const Blogs = ({ pageContext: { blog } }) => {
  const renderSections = (section, index) => {
    switch (section?._type) {
      case "contentEditor":
        return <BlogsDetail section={section} blogs={blog} key={index} />;

      default:
        return <></>;
    }
  };
  return (
    <>
      <Layout
        title={blog?.title}
        blog={blog}
        seo={blog?.seo}
        socialsharing={blog?.socialSharing}
      >
        <>
          {blog?.content?.length ? (
            <div>
              {blog?.content?.map((section, index) =>
                renderSections(section, index)
              )}
            </div>
          ) : (
            <div></div>
          )}
        </>
      </Layout>
    </>
  );
};

export default Blogs;
