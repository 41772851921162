import React, { useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { generateImageUrl } from "../../../client";
import moment from "moment";
const BlockContent = require("@sanity/block-content-to-react");

const BlogsDetail = ({ section, blogs }) => {
  const [downloading, setDownloading] = useState(false);
  console.log("blogs", blogs);

  const downloadBlog = async (id) => {
    setDownloading(true);
    try {
      if (typeof window !== "undefined") {
        const padHorizontal = 24; // 0.25 Inch
        const padVertical = 24; // 0.25 Inch
        const inputDiv = document.getElementById(id);
        document.body.appendChild(inputDiv);
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pageWidth = pdf.internal.pageSize.width - padHorizontal * 2;
        const pageHeight = pdf.internal.pageSize.height - padVertical * 2;
        const divWidth = inputDiv.clientWidth;
        const divHeight = inputDiv.clientHeight;
        let offsetHeight = 0;
        inputDiv.style.color = "#000000";

        const imageHeight = (pageHeight * divWidth) / pageWidth;
        while (offsetHeight < divHeight) {
          const actualHeight =
            offsetHeight + imageHeight < divHeight
              ? imageHeight
              : divHeight - offsetHeight;

          const canvas = await html2canvas(inputDiv, {
            x: 0,
            y: offsetHeight,
            width: divWidth,
            height: actualHeight,
          });

          const contentDataURL = canvas.toDataURL("image/png");
          const actualPageHeight = (actualHeight * pageHeight) / imageHeight;

          pdf.addImage(
            contentDataURL,
            "PNG",
            padHorizontal,
            padVertical,
            pageWidth,
            actualPageHeight,
            "",
            "FAST"
          );

          if (offsetHeight + imageHeight < divHeight) {
            pdf.addPage();
          }

          offsetHeight = offsetHeight + imageHeight;
        }

        inputDiv.style.color = "#ffffff";

        pdf.save(`${blogs?.title?.split(" ").join("_")}.pdf`);

        inputDiv.remove();
        setDownloading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const serializers = {
    types: {
      block: (props) => {
        switch (props.node.style) {
          case "normal":
            return <p>{props.children}</p>;
          case "h1":
            return <p className="text-3xl bold py-2">{props.children}</p>;
          case "h2":
            return <p className="text-2xl semibold py-2">{props.children}</p>;
          case "h3":
            return <p className="text-xl semi py-2">{props.children}</p>;

          case "h4":
            return <p className="text-xl semi py-2">{props.children}</p>;

          case "headline":
            return (
              <h3 className="text-4xl carmorant-font">{props.children}</h3>
            );
          case "smaller":
            return <p className="text-xl carmorant-font">{props.children}</p>;
          default:
            return <p>{props.children}</p>;
        }
      },
      image: (props) => {
        if (props?.node?.asset?._ref) {
          return (
            <div
              className="w-full my-6 min-h-[370px] md:min-h-[300px] lg:min-h-[300px]"
              style={{
                backgroundImage: `url(${generateImageUrl(
                  props?.node?.asset?._ref
                )})`,
                backgroundPosition: `center`,
                backgroundSize: `cover`,
                loading: `lazy`,
              }}
            ></div>
          );
        }
      },
    },

    marks: {
      highlight: (props) => {
        return (
          <span className="highlight text-3xl  text-white font-bold font-Dazzed">
            {props.children}
          </span>
        );
      },
    },
    lists: (props) => {
      return <ul>{props?.children}</ul>;
    },
    listItem: (props) => {
      return (
        <li className="list-disc text-xl leading-6 mb-3 ml-4">
          {props.children}
        </li>
      );
    },
  };

  return (
    <>
      {!downloading ? (
        <div>
          <div className="flex flex-col items-start text-white ">
            {blogs?.heroImage?.asset?._ref && (
              <div
                className="w-full p-4 min-h-[370px] md:min-h-[300px] lg:min-h-[700px]  relative lg:top-0 top-20 sm:top-4 md:top-12"
                style={{
                  backgroundImage: `url(${generateImageUrl(
                    blogs?.heroImage?.asset?._ref
                  )})`,
                  backgroundPosition: `center`,
                  backgroundSize: `cover`,
                  loading: `lazy`,
                }}
              ></div>
            )}
            <div id="downloadDiv" className="w-[92%] mx-auto">
              <h1 className="pt-8 pb-4 text-inherit text-3xl mt-20 sm:mt-8 lg:mt-0">
                {blogs?.title}
              </h1>
              <h1 className="text-inherit text-xl py-2">
                <span className="mr-2">Published Date</span>
                {moment(blogs?.date).format("DD-MM-YYYY")}
              </h1>
              <BlockContent
                className="blog-conent text-inherit flex justify-center flex-col items-start"
                blocks={section.content}
                serializers={serializers}
              />
              <p className="text-[22px] text-white font-semibold pb-2">
                {blogs?.goldTable[0]?.tableHeading}
              </p>
              <table className="border-solid border-[1px] border-gray-500 w-full">
                <tbody>
                  {blogs?.goldTable[0]?.table?.rows?.map((single, index) => (
                    <tr
                      key={index}
                      className={`cursor-pointer hover:text-[#FCD462] hover:bg-[#353530] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500 ${
                        index === 0 &&
                        "text-[#FCD462] cursor-default hover:bg-[#1c1b19]"
                      }`}
                    >
                      {single?.cells?.map((value, index) => (
                        <td
                          key={index}
                          className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px]"
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className=" pt-12">
                <svg
                  onClick={() => downloadBlog("downloadDiv")}
                  className="cursor-pointer"
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22.000000 17.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,17.000000) scale(0.100000,-0.100000)"
                    fill="#ffffff"
                    stroke="none"
                  >
                    <path
                      d="M90 145 c0 -18 -5 -25 -20 -25 -28 0 -25 -11 10 -45 l30 -29 30 29
   c35 34 38 45 10 45 -15 0 -20 7 -20 25 0 18 -5 25 -20 25 -15 0 -20 -7 -20
   -25z"
                    />
                    <path
                      d="M0 20 c0 -19 7 -20 110 -20 103 0 110 1 110 20 0 11 -7 20 -15 20 -8
   0 -15 -4 -15 -10 0 -6 -33 -10 -80 -10 -47 0 -80 4 -80 10 0 6 -7 10 -15 10
   -8 0 -15 -9 -15 -20z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center text-white text-2xl py-24">
          Downloading...
        </div>
      )}
    </>
  );
};
export default BlogsDetail;
